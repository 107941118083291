export const GlobalVariables = {
    production: false,
    app_name : 'AKATAS Abacus center',
    app_version : '2.4',
    app_devname : '-',
    app_wano :'919175001199',
    app_mobile : '919175001199',
    app_email : 'contact@akatas.in',
    app_website : 'https://akatas.in',
    app_update_link : 'https://akatas.in/apk/ems_update.xml',
    dev_name : 'akashganga it services',
    dev_mobile : '918788680323',
    dev_wano : '918788680323',
    fcm_topic_center : 'center',
    stud_welcome_msg : '🧮 AKATAS अबॅकस अकॅडमी मध्ये आपले सहर्ष स्वागत आपल्या पाल्याच्या उज्वल भविष्यासाठी AKATAS अबॅकस मध्ये प्रवेश घेतल्याबद्दल आभार..!🙏🏻',
    fee_receipt_url : 'download-fee-receipt/'
  };