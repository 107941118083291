import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
// import { HTTP } from '@ionic-native/http/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { Uid } from '@ionic-native/uid/ngx';
// import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
// import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Camera } from '@ionic-native/camera/ngx';
// import { File } from '@ionic-native/file/ngx';
// import { Crop } from '@ionic-native/crop/ngx';
import { CartModalPageModule } from './pages/cart-modal/cart-modal.module';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { FCM } from '@ionic-native/fcm/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CartModalPageModule
  ],
  providers: [
    // StatusBar,
    // SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // HTTP,
    AppUpdate,
    CallNumber,
    SocialSharing,
    // StreamingMedia,
    UniqueDeviceID,
    Uid,
    Camera,
    FCM,
    // AndroidPermissions,
    // ScreenOrientation,
    // Crop,
    // File
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
