import { Component } from '@angular/core';
import { GlobalVariables } from 'src/app/global-variables';
import { APP_NAME, APP_UPDATE_LINK, APP_VERSION, DEV_MOBILE, DEV_NAME, DEV_WANO, MyconnserviceService } from './services/myconnservice.service';
import { Platform } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActionSheetController } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AppUpdate } from '@ionic-native/app-update/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  dev_phone:string = '8788680323';
  appname:any = GlobalVariables.app_name;  
  appversion:any = GlobalVariables.app_version;
  dev_name:any = GlobalVariables.app_devname;  
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'My Branches',
      url: '/my-branches',
      icon: 'home'
    },
    {
      title: 'Add Students',
      url: '/add-student',
      icon: 'person'
    },
    {
      title: 'Profile',
      url: '/profile',
      icon: 'person'
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'exit'
    }
  ];

  constructor(
    private platform: Platform,
    private appUpdate: AppUpdate,
    private callNumber: CallNumber,
    public actionSheetController: ActionSheetController,
    private socialSharing: SocialSharing  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      const updateUrl = APP_UPDATE_LINK;
      this.appUpdate.checkAppUpdate(updateUrl).then(update => {
        // alert("Update Status:  "+update.msg);
      }).catch(error=>{
        // alert("Error: "+error.msg);
      });

    });
  }

  async visit_developer(imgtype = 0) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Contact Developer',
      buttons: [{
        text: 'Call Us',
        icon: 'call',
        handler: () => {
          this.call_developer();
        }
      }, {
        text: 'Whatsapp Us',
        icon: 'logo-whatsapp',
        handler: () => {
          this.whatapp_developer();
        }
      }]
    });
    await actionSheet.present();
  }


  call_developer()
  {
    this.callNumber.callNumber(DEV_MOBILE, true)
    .then(res => console.log('Launched dialer!', res))
    .catch(err => console.log('Error launching dialer', err));
  }

  whatapp_developer()
  {
    this.socialSharing.shareViaWhatsAppToReceiver(DEV_WANO,'').then((res) => {
    }).catch((e) => {
    });
    // alert('Whatsapp us');
  }

}
