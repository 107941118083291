import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
// import { HTTP } from '@ionic-native/http/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable,from  } from 'rxjs';
import { map,finalize } from "rxjs/operators";
import { Platform,LoadingController,AlertController } from '@ionic/angular';
//import 'rxjs/add/operator/map'

export const APP_NAME = 'akatas Abacus Center';
export const APP_VERSION = '2.4';
export const APP_WANO ='917057258214';
export const APP_MOBILE = '917057258214';
export const APP_EMAIL = 'contact@akatasabacus.com';
export const APP_WEBSITE = 'https://akatasabacus.com';
export const APP_UPDATE_LINK = 'https://akatasabacus.com/apk/ems_update.xml';
export const DEV_NAME = 'AKASHGANGA IT SERVICES';
export const DEV_MOBILE = '918788680323';
export const DEV_WANO = '918788680323';
export const FCM_TOPIC_CENTER = 'center';
export const STUD_WELCOME_MSG = '🧮 आर्य अबॅकस अकॅडमी मध्ये आपले सहर्ष स्वागत आपल्या पाल्याच्या उज्वल भविष्यासाठी आर्य अबॅकस मध्ये प्रवेश घेतल्याबद्दल आभार..!🙏🏻';
export const FEE_RECEIPT_URL = 'download-fee-receipt/';

@Injectable({
  providedIn: 'root'
})

export class MyconnserviceService {
  public update_link:string = '';

  baseUrl:string;
  imgUrl:string;
  uploadUrl:string;
  loaderToShow: any;
  data:any;
  sel_branch:any;


  public api_response : any = {};
  APP_NAME: any;
  constructor(public loadingController: LoadingController, private http:HttpClient, private plt: Platform, public alertController: AlertController) { 
    // this.baseUrl = 'http://192.168.2.22/akatasabacus_ems_ums/Appapi/';
    // this.imgUrl = 'http://192.168.2.22/akatasabacus_ems_ums/resources/assets/';

    //  this.baseUrl = 'http://localhost/akatas_ems/';
    //  this.imgUrl = 'http://localhost/akatas_ems/resources/assets/';
    //  this.uploadUrl = 'http://localhost/akatasabacus_ems_ums/assets/upload/';

    this.baseUrl = 'https://ems.akatas.in/';
    this.imgUrl = 'https://ems.akatas.in/resources/assets/'; 
  }

verifyLogin(uname,upass){
    return this.http.get(this.baseUrl+'verify_login/'+uname+'/'+upass)
    .pipe(map(results => results));
}

getHomePageData(accountid){
    return this.http.get(this.baseUrl+'home_pagedata/'+accountid)
    .pipe(map(results => results));
} 

getCenterBranches(accountid){
  return this.http.get(this.baseUrl+'get_center_branches/'+accountid)
  .pipe(map(results => results));
}

getAddStudentData(accountid){
  return this.http.get(this.baseUrl+'get_addstud_Data/'+accountid)
  .pipe(map(results => results));
}

getSubjectsChapters(idsubject){
  return this.http.get(this.baseUrl+'subject_chapters/'+idsubject)
  .pipe(map(results => results));
} 

getAccountProfile(idaccount){
  return this.http.get(this.baseUrl+'account_profile/'+idaccount)
  .pipe(map(results => results));
} 

changePreflang(accountid,sel_lang){
  return this.http.get(this.baseUrl+'change_pref_lang/'+accountid+'/'+sel_lang)
  .pipe(map(results => results));
} 

getChaptersTopics(iduser,pref_lang,idchapter){
  return this.http.get(this.baseUrl+'chapters_topics/'+iduser+'/'+pref_lang+'/'+idchapter)
  .pipe(map(results => results));
} 

updateTopicStatus(iduser,idtopic){
  return this.http.get(this.baseUrl+'update_topic_status/'+iduser+'/'+idtopic)
  .pipe(map(results => results));
} 

getCounselings(){
  return this.http.get(this.baseUrl+'get_counselings/')
  .pipe(map(results => results));
} 



sendPostRequest_(postlink,postData) {


  // const httpOptions = {
    // headers: new HttpHeaders({
    //   'Accept':'*/*',
    //   'Content-Type':  'application/json',
    //   'Authorization': 'Bearer ' + localStorage.getItem("utoken")
    //   })
    // };
  var pdata = new Array();
  pdata = postData;
  
  var api_link = this.baseUrl + postlink;  
  var element = {};
  //element['utoken'] = ;;
  pdata.push({'utoken':localStorage.getItem("utoken")});

  alert('Data' + JSON.stringify(pdata));

  this.http.post(api_link, pdata)
    .subscribe(data => {
      console.log('Data from Boody' + JSON.stringify(data['_body']['data']));
     }, error => {
      console.log(error);
    });
}

sendPostRequest(postlink,postData){
  var api_link = this.baseUrl + postlink;  
  var pdata;
  // pdata.push({'utoken':localStorage.getItem("utoken")});
  pdata = JSON.stringify({
    'utoken':localStorage.getItem("utoken"),
    'postdata' : postData,
    'app_version' : APP_VERSION
    });
  
  return this.http.post(api_link, pdata);
}





setData(id, data) {
  this.data[id] = data;
}

getData(id) {
  return this.data[id];
}





showAlert_error(message) {
  this.alertController.create({
    header: 'Error',
    cssClass: 'show_alert_error',
    // subHeader: '',
    message: message,
    buttons: ['OK']
  }).then(res => {
    res.present();
  });
}

showAlert_success(message) {
  this.alertController.create({
    header: 'Success',
    cssClass: 'show_alert_success',
    // subHeader: '',
    message: message,
    buttons: ['OK']
  }).then(res => {
    res.present();
  });
}



}
