import { Product, CartService } from './../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { MyconnserviceService } from '../../services/myconnservice.service';
import { LoadingService } from '../../services/loading.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.page.html',
  styleUrls: ['./cart-modal.page.scss'],
})
export class CartModalPage implements OnInit {
  apiResponse:any;
  cart: Product[] = [];
  order_notes:any;
  branches:any = [];
  sel_branch:any;
  constructor(private cartService: CartService, private modalCtrl: ModalController, private alertCtrl: AlertController,
    private myLoader: LoadingService,public myconnserviceService:MyconnserviceService, private router: Router) { }
 
  ngOnInit() {
    var postData = {};
    var response = this.myconnserviceService.sendPostRequest('get-center-branches',postData).subscribe(
      (responseData)=>{
        this.apiResponse = responseData;
          if(this.apiResponse['status'] == 1)
          {
            this.branches = this.apiResponse['data']['branches'];    
            this.sel_branch = this.branches[0].idbranches;
          }else{
            alert('No Data Found! Try Again');
          }
          // this.ionLoaderService.dismissLoader();        
      });


    this.cart = this.cartService.getCart();
  }
 
  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
  }
 
  increaseCartItem(product) {
    this.cartService.addProduct(product);
  }
 
  removeCartItem(product) {
    this.cartService.removeProduct(product);
  }
 
  getTotal() {
    return this.cart.reduce((i, j) => i + j.price * j.amount, 0);
  }
 
  close() {
    this.modalCtrl.dismiss();
  }
 
  async checkout() { 

    this.myLoader.present();

    var postData = {
      'cartdata':this.cart,
      'idbranch':this.sel_branch,
      'order_notes':this.order_notes
    };
    var response = this.myconnserviceService.sendPostRequest('submit-cart-order',postData).subscribe(
      (responseData)=>{
        this.apiResponse = responseData;
        // console.log('Data : '  + this.apiResponse);  
        if(this.apiResponse['status'] == 1)
          {  
            this.cart = [];
            this.order_notes = '';
            this.modalCtrl.dismiss();
            this.myconnserviceService.showAlert_success('Order Placed Successfully. Order ID #'+ this.apiResponse['data']['orderid']);
            this.myLoader.dismiss();
            this.router.navigate(['/my-orders']);
          }else{
            this.myLoader.dismiss();
          }
      });
  }
}